import { enumToKeyValueArray, Option } from '../utils/generic';

export type Job = {
  id: string;
  jobStatusID: JobStatus;
  active: boolean;
  userId: string;
  propertyId: string;
  pasHubJobId: string;
  createdDate: Date;
  currentEpc: string;
  actualEpc: string;
  newEpc: string;
  eligible: boolean;
  potentialEligibleSchemeNames: string[];
};

export enum JobStatus {
  NEW = 1,
  PENDING = 2,
  SURVEY = 3,
  REPORT = 4,
  INSTALLATION = 5,
  COMPLETE = 6,
  EXPIRED = 7,
}

export const JobStatusStrings = {
  [JobStatus.NEW]: 'new',
  [JobStatus.PENDING]: 'pending',
  [JobStatus.SURVEY]: 'survey',
  [JobStatus.REPORT]: 'report',
  [JobStatus.INSTALLATION]: 'installation',
  [JobStatus.COMPLETE]: 'complete',
  [JobStatus.EXPIRED]: 'expired',
};

export const jobStatusOptions: Option<string | JobStatus>[] = enumToKeyValueArray(JobStatus);

export type JobSurvey = {
  id: string;
  jobId: string;
  surveyStatusID: SurveyStatus;
  active: boolean;
  requestedBookingDate: Date;
  requestedBookingSlot: number;
  jobActive: boolean;
};

export type JobInstallation = {
  id: string;
  jobId: string;
  installationStatusId: InstallationStatus;
  active: boolean;
  selectedImprovementId: string;
  adminSelectedImprovementId: string;
};

export enum SurveyStatus {
  NEW_SURVEY = 1,
  SURVEY_REQUESTED = 2,
  SURVEY_BOOKED = 3,
  SURVEY_COMPLETED = 4,
  SURVEY_CANCELLED = 5,
  REPORT_IN_PROGRESS = 6,
  REPORT_COMPLETED = 7,
}

export const SurveyStatusStrings = {
  [SurveyStatus.NEW_SURVEY]: 'Survey Pending',
  [SurveyStatus.SURVEY_REQUESTED]: 'Survey Booking Requested',
  [SurveyStatus.SURVEY_BOOKED]: 'Survey Booked',
  [SurveyStatus.SURVEY_COMPLETED]: 'Survey Completed',
  [SurveyStatus.SURVEY_CANCELLED]: 'Survey Booking Cancelled',
  [SurveyStatus.REPORT_IN_PROGRESS]: 'Report In Progress',
  [SurveyStatus.REPORT_COMPLETED]: 'Report Completed',
};

export enum InstallationStatus {
  NEW_INSTALLATION = 1,
  INSTALLATION_REQUESTED = 2,
  INSTLLATION_BOOKED = 3,
  INSTALLATION_IN_PROGRESS = 4,
  INSTALLATION_COMPLETED = 5,
  INSTALLATION_CANCELLED = 6,
}

export const InstallationStatusStrings = {
  [InstallationStatus.NEW_INSTALLATION]: 'Installation Pending',
  [InstallationStatus.INSTALLATION_REQUESTED]: 'Installation Booking Requested',
  [InstallationStatus.INSTLLATION_BOOKED]: 'Installation Booked',
  [InstallationStatus.INSTALLATION_IN_PROGRESS]: 'Installation In Progress',
  [InstallationStatus.INSTALLATION_COMPLETED]: 'Installation Completed',
  [InstallationStatus.INSTALLATION_CANCELLED]: 'Installation Booking Cancelled',
};

export type ImprovementDto = {
  id: string;
  jobId: string;
  cost: number;
  items: ImprovementItemDto[];
  newEpc?: string;
};

export type ImprovementItemDto = ImprovementItem & {
  id: string;
  improvementId: string;
  improvementItemType?: ImprovementItemType;
};

export type ImprovementItem = {
  itemText: string;
  itemCost: number;
  co2Savings?: number;
  monetarySavings?: number;
  improvementItemType?: ImprovementItemTypeDto;
};

export type ImprovementItemTypeDto = ImprovementItemType & {
  name: string;
  staticAmount: number;
};

export type ImprovementItemType = {
  description: string;
  isDiscount: boolean;
};

export type QuickSelectImprovementItem = {
  key: string;
  value: number;
  defaultCost: number;
  textEditable: boolean;
  costEditable: boolean;
  hideSavingsFields?: boolean;
};

export type Note = {
  id: number;
  authorId: string;
  author: string;
  content: string;
  created: string;
  position: string;
};

export enum BookingSlotTimeframe {
  AmSlotStartHour = '08:00:00',
  AmSlotEndHour = '12:00:00',
  PmSlotStartHour = '13:00:00',
  PmSlotEndHour = '17:00:00',
}
